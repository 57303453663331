<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.firstName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.firstName + " " + userData.lastName }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$can('ur_update')"
                :to="{ name: 'user-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                v-if="$can('ur_delete')"
                variant="outline-danger"
                class="ml-1"
                @click="deleteUser(userData.id)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <!-- <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                23.3k
              </h5>
              <small>Monthly Sales</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="TrendingUpIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                $99.87k
              </h5>
              <small>Annual Profit</small>
            </div>
          </div>
        </div> -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <!-- TODO change to is Active
              {{ userData.status }} -->
              Active
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.role.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Job title</span>
            </th>
            <td class="pb-50">
              {{ userData.jobTitle }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr v-if="userData.startDate">
            <th>
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Duration of user</span>
            </th>
            <td class="pb-50">
              {{ formatDate(userData.startDate) }} - {{ formatDate(userData.endDate) }}
            </td>
          </tr>
          <tr v-if="userData.reason">
            <th>
              <feather-icon
                icon="AlertCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Reason</span>
            </th>
            <td class="pb-50">
              {{ userData.reason }}
            </td>
          </tr>
          <tr v-if="userData.auditedByPWC">
            <th>
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">GUP audited</span>
            </th>
            <td class="pb-50">
              {{ mapAnswer(userData.auditedByPWC) }}
            </td>
          </tr>
          <tr v-if="userData.auditedInMY">
            <th>
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Locally audited</span>
            </th>
            <td class="pb-50">
              {{ mapAnswer(userData.auditedInMY) }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '@/views/userManager/useUsersList'
import store from '@/store'
import router from '@/router'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  computed: {
    ...mapGetters('app', ['answerQuestionOptions']),

  },
  methods: {
    mapAnswer(value) {
      return this.answerQuestionOptions.find(x => x.value === value).label
    },
    formatDate(time) {
      return moment(time).format('DD/MM/YYYY')
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this user.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('user/deleteUser', { id })
              .then(() => {
                router.push({ name: 'user-manager' })
              })
          }
        })
    },
  },
}
</script>

<style>

</style>
