<!-- eslint-disable import/no-cycle -->
<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-permissions-card
            :module-array="moduleArray"
          />
        </b-col>
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-timeline-card v-if="$can('tracking_read')"
            :target="firstTarget"
            :actor="firstActor"
          />
        </b-col>
        <!-- <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-timeline-card v-if="$can('tracking_read')"
            :target="secondTarget"
            :actor="secondActor"
          />
        </b-col> -->
      </b-row>

    </template>

  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import UserViewUserInfoCard from '@/components/userManager/detail/UserViewUserInfoCard.vue'
import UserViewUserPlanCard from '@/components/userManager/detail/UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from '@/components/userManager/detail/UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from '@/components/userManager/detail/UserViewUserPermissionsCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
  },
  setup() {
    const firstTarget = ''
    const firstActor = router.currentRoute.params.id
    const secondTarget = `cmsConnection:user:${router.currentRoute.params.id}`
    const secondActor = ''
    const userData = ref(null)
    const moduleArray = ref([])
    const arrMap = []
    const fetchRole = () => {
      store.dispatch('authenticate/fetchRole', userData.value.roleId)
        .then(response => {
          const data = response.body.permissions
          data.forEach(element => {
            if (!moduleArray.value.filter(e => e.module === element.object).length > 0) {
              const objectModule = {
                module: element.object,
                read: false,
                write: false,
                create: false,
                delete: false,
              }
              moduleArray.value.push(objectModule)
            }
          })
          data.forEach(x => {
            const objIndex = moduleArray.value.findIndex(obj => obj.module === x.object)
            moduleArray.value[objIndex][`${x.code.split('_')[1]}`] = true
          })
        })
        .catch(error => {
          // this.$bvtoast({
          //   component: ToastificationContent,
          //   props: {
          //     title: error.response.data.message,
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
        })
    }
    store.dispatch('user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.body
        fetchRole()
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    return {
      firstTarget,
      firstActor,
      secondTarget,
      secondActor,
      arrMap,
      userData,
      moduleArray,
    }
  },
}
</script>

<style>

</style>
